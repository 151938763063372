import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { MICRO_APP_NAME } from './utils/constants';

const StandaloneScriptSightsApp = React.lazy(() =>
  import('./ScriptSightsApp').then((module) => ({ default: module.StandaloneScriptSightsApp }))
);

const configuration = { appId: 'studios-portal-app' };

const radioConfig = { channelNames: ['universal-header'] };

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-universal-header-app',
    packageName: '@amzn/studios-universal-header-app',
    version: '*',
    accessLevel: 'restricted',
  },
];

const rootComponent = () => (
  <StandaloneConfiguration
    RootComponent={StandaloneScriptSightsApp}
    configuration={configuration}
    microAppName={MICRO_APP_NAME}
    microApps={microApps}
    radioConfig={radioConfig}
    standaloneModeFeatures={{ microAppUserPool: true }}
  />
);

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <ErrorBoundary error={error} info={info} />
    </ThemeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
